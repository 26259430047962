import React from "react";
import "./Logo.css";

function Logo() {
  return (
    <div className="my-logo">
      <svg
        width={50}
        height={50}
        viewBox="0 0 78 78"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          id="Ellipse-logo"
          cx={39}
          cy={39}
          r="36.5"
          stroke="#64FFDA"
          strokeWidth={5}
        />
        <path
          id="R-logo"
          d="M26.7841 56V21.0909H40.5568C43.1932 21.0909 45.4432 21.5625 47.3068 22.5057C49.1818 23.4375 50.608 24.7614 51.5852 26.4773C52.5739 28.1818 53.0682 30.1875 53.0682 32.4943C53.0682 34.8125 52.5682 36.8068 51.5682 38.4773C50.5682 40.1364 49.1193 41.4091 47.2216 42.2955C45.3352 43.1818 43.0511 43.625 40.3693 43.625H31.1477V37.6932H39.1761C40.5852 37.6932 41.7557 37.5 42.6875 37.1136C43.6193 36.7273 44.3125 36.1477 44.767 35.375C45.233 34.6023 45.4659 33.642 45.4659 32.4943C45.4659 31.3352 45.233 30.358 44.767 29.5625C44.3125 28.767 43.6136 28.1648 42.6705 27.7557C41.7386 27.3352 40.5625 27.125 39.142 27.125H34.1648V56H26.7841ZM45.6364 40.1136L54.3125 56H46.1648L37.6761 40.1136H45.6364Z"
          fill="#64FFDA"
        />
      </svg>
    </div>
  );
}

export default Logo;
